import { golfCoursePropertiesDetails } from '@marriott/mi-rnb-graphql';

export const golfCourseLocationsMock = {
  data: {
    searchPropertiesByDestination: {
      total: 9,
      edges: [
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Westin Hotels & Resorts',
                id: 'WI',
              },
              descriptions: [
                {
                  text: 'The Westin Reserva Conchal an All Inclusive Golf Resort and Spa is an eco friendly retreat set between the exotic forests and unspoiled beaches of Costa Ricas North Pacific Riviera. Our energizing Costa Rica all inclusive resort embraces the Pura Vida lifestyle by balancing soothing escape and local adventure. At 2400 acres our Playa Conchal all inclusive resort offers everything you need to thrive in Costa Rica including a location near the beach, a lagoon style pool a Westin WORKOUT Fitness Studio, tennis courts and a championship golf course. Enhance your stay with Westin Kids Club, free fine dining and the Heavenly Spa by Westin. Recharge in one of our 406 spacious guest rooms featuring Westin Heavenly Beds and high speed internet access. Balconies and terraces in select rooms are ideal for soaking up the sun and engaging with the lively vistas of Costa Rica. Discover pure refreshment at The Westin Reserva Conchal an All Inclusive Golf Resort and Spa.',
                },
                {
                  text: 'The Westin Reserva Conchal, an All-Inclusive Golf Resort and Spa is an eco-friendly beach resort in Costa Rica. Amenities at our Playa Conchal all-inclusive resort include a lagoon-style pool, a WestinWORKOUT Fitness Studio, tennis courts and golf.',
                },
                {
                  text: 'The Westin Reserva Conchal, an All-Inclusive Golf Resort and Spa is an eco-friendly beach resort in Costa Rica. Amenities at our Playa Conchal all-inclusive resort include a lagoon-style pool, a WestinWORKOUT Fitness Studio, tennis courts and golf.',
                },
                {
                  text: "The Westin Reserva Conchal, an All-Inclusive Golf Resort & Spa is located near Playa Conchal in Costa Rica's Guanacaste Province. Guests at our Conchal all-inclusive resort are approximately 45 minutes from Daniel Oduber Quirós International Airport.",
                },
                {
                  text: "The Westin Reserva Conchal, an All-Inclusive Golf Resort & Spa is located near Playa Conchal in Costa Rica's Guanacaste Province. Guests at our Conchal all-inclusive resort are approximately 45 minutes from Daniel Oduber Quirós International Airport.",
                },
                {
                  text: 'Relax at our Costa Rica all-inclusive beach resort',
                },
                {
                  text: 'Rooms surrounded by the mysticism of a luxurious paradise',
                },
                {
                  text: 'A Costa Rica all-inclusive beach resort offering the quintessential Pura Vida experience.',
                },
              ],
              name: 'The Westin Reserva Conchal, an All-Inclusive Golf Resort & Spa',
            },
            id: 'LIRWI',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LIRWI/lirwi-golf-5804-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'lirwi-golf-5804',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LIRWI/lirwi-golf-5806-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'lirwi-golf-5806',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/wi-lirwi-2022-01-229336-77942:Classic-Hor',
                        },
                        alternateDescription: 'Golf',
                        title: '2022-01-229336',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/wi-lirwi-hyperlapse-0281-31472-18996:Classic-Hor',
                        },
                        alternateDescription: 'Aerial view of  Reserva Conchal Golf Club ',
                        title: 'HYPERLAPSE 0281 31472',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/wi-lirwi-foto-hoyo-5-feb41289-04306:Classic-Hor',
                        },
                        alternateDescription: 'Reserva Conchal Golf Club ',
                        title: 'Foto Hoyo 5 Feb41289',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/wi-lirwi-foto-hoyo-17-38333:Classic-Hor',
                        },
                        alternateDescription: 'Golf ',
                        title: 'Foto Hoyo 17',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/wi-lirwi-conchal-7489-40156-59921:Classic-Hor',
                        },
                        alternateDescription: 'golf',
                        title: 'conchal-7489-40156',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LIRWI/lirwi-golf-4040-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'lirwi-golf-4040',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LIRWI/lirwi-golf-4041-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'lirwi-golf-4041',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/wi-lirwi-westin-conchal-20294-17836:Classic-Hor',
                        },
                        alternateDescription: 'Golf  ',
                        title: 'Westin Conchal 20294',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'غولف',
                        title: '2022-01-229336',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'ゴルフ',
                        title: '2022-01-229336',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'Golfe',
                        title: '2022-01-229336',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'golf',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: '高尔夫球',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'الجولف',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'golfe',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: '골프',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'Golf',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'golf',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: null,
                        },
                        alternateDescription: 'Golf',
                        title: '2022-01-25 174609',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LIRWI/lirwi-golf-view-9125-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf View',
                        title: 'lirwi-golf-view-9125',
                      },
                    },
                  ],
                },
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Guanacaste',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'W Hotels',
                id: 'WH',
              },
              descriptions: [
                {
                  text: "Hidden amongst the mangroves of Guanacaste, the hotel W Costa Rica Reserva Conchal is a modern oasis where undercover rebels let their spirits roam freely. Our Costa Rica luxury resort is surrounded by exotic landscapes and the sugar-white sands of Playa Conchal. Inspired by the vibrant culture of Guanacaste, our luxury hotel resort boasts a bold design that audaciously reinvents the idea of luxury. Admire the views of our reserve from the blissful serenity of plush bedding before splashing into your suite's private plunge pool. After an active day of golfing, surfing, or sunbathing, retreat to our resort spa, AWAY®, and sip on a martini while we pamper you from head to toe with local treatments. When the sun goes down, the fun is just getting started. Indulge at one of our hotel restaurants before discovering new beats by our Resident DJ in our Living Room. Whether you're an exotic explorer or a luxury lover, expect an unparalleled stay at W Costa Rica-Reserva Conchal, a luxury hotel in Costa Rica",
                },
                {
                  text: 'This modern Guanacaste hotel is a luxurious oasis boasting amenities such as vibrant dining, an infinity pool, a day spa and a world-class golf course. Accommodations feature bold designs, a fully stocked bar, private balconies and plunge pools.',
                },
                {
                  text: 'This modern Guanacaste hotel is a luxurious oasis boasting amenities such as vibrant dining, an infinity pool, a day spa and a world-class golf course. Accommodations feature bold designs, a fully stocked bar, private balconies and plunge pools.',
                },
                {
                  text: 'This Guanacaste hotel is on a nature reserve surrounded by exotic landscapes and the shoreline. This hotel is near the world-class surfing town of Tamarindo Beach, several natural attractions and various restaurants and clubs.',
                },
                {
                  text: 'This Guanacaste hotel is on a nature reserve surrounded by exotic landscapes and the shoreline. This hotel is near the world-class surfing town of Tamarindo Beach, several natural attractions and various restaurants and clubs.',
                },
                {
                  text: 'Kids activities in the games room, non-motorized water activities: bikes for the entire stay, kayak (1 hour per day), paddle boards (1 hour per day) and snorkeling equipment, selected daily resort activities (additional costs may apply on certain activities), special fitness classes according to schedule, use of golf driving range, valet parking, welcome drink (alcoholic and non-alcoholic) and Wi-Fi access.',
                },
                {
                  text: 'Indulge in the vibrant life of our Guanacaste hotel',
                },
                {
                  text: 'Satisfy your craving for bliss in our hotel rooms',
                },
                {
                  text: 'Discover a modern oasis at our Costa Rica luxury resort, hidden between the mangroves of Guanacaste.',
                },
              ],
              name: 'W Costa Rica - Reserva Conchal Hotel',
            },
            id: 'LIRWH',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'asdasd',
                        title: 'dacp-api-test3',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'TESTING',
                        title: 'lirwh-bathroom-detail-4910',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/LIRWH/lirwh-king-guestroom-4904-hor-clsc.jpg',
                        },
                        alternateDescription: 'TESTING',
                        title: 'lirwh-king-guestroom-4904',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'TESTING',
                        title: 'lirwh-guestroom-details-4913',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'TESTING',
                        title: 'lirwh-bathroom-detail-4908',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/LIRWH/lirwh-escape-guestroom-7412-hor-clsc.jpg',
                        },
                        alternateDescription: 'TESTING',
                        title: 'lirwh-escape-guestroom-7412',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/LIRWH/lirwh-king-guestroom-4902-hor-clsc.jpg',
                        },
                        alternateDescription: 'TESTING',
                        title: 'lirwh-king-guestroom-4902',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7stage/wh-lirwh-floorplan-21969:Classic-Hor',
                        },
                        alternateDescription: 'TESTING',
                        title: 'Floorplan',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/wh-lirwh-nueva-feb-202-22833-98648:Classic-Hor',
                        },
                        alternateDescription: 'Reserva Conchal Golf Club ',
                        title: 'nueva-feb-202-22833',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/wh-lirwh-lapse-0281-edit-12517-82729:Classic-Hor',
                        },
                        alternateDescription: 'Aerial view of  Reserva Conchal Golf Club ',
                        title: 'lapse-0281-edit-12517',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'test rendering',
                        title: 'test rendering',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'asdad',
                        title: 'dac-api-test-old-marsha2',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '',
                        },
                        alternateDescription: 'asdasda',
                        title: 'dac-api-create-tests',
                      },
                    },
                  ],
                },
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Guanacaste',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Marriott Hotels & Resorts',
                id: 'MC',
              },
              descriptions: [
                {
                  text: "Experience a seamless blend of high-tech and high-touch service at Costa Rica Marriott Hotel Hacienda Belen. After landing at Juan Santamaría International Airport, your stay at our Belen, Costa Rica hotel begins almost immediately. Our convenient location offers unparalleled access to many prominent locales such as the San Antonio district and Britt's Coffee Tour. After exploring our grounds, featuring a golf driving range and tennis court, pamper yourself at our full-service spa. Then, unwind at our Heredia hotel with spacious rooms with luxury bedding and mini-refrigerators. When hunger strikes, satisfy your appetite with international cuisine from one of our on-site restaurants. To stay active, visit our fitness center and outdoor pool. Need to host an event? Choose from one of our 28 venues for your convention or reception. Whatever the reason for your travels, Costa Rica Marriott Hotel Hacienda Belen provides an elevated experience you won't soon forget.",
                },
                {
                  text: 'Enjoy a stay at Costa Rica Marriott Hotel Hacienda Belen, boasting colonial architecture and a free airport shuttle. Relax in modern rooms and suites, unwind with dining and spa services, and host an event in the versatile venues at our Heredia hotel.',
                },
                {
                  text: 'Enjoy a stay at Costa Rica Marriott Hotel Hacienda Belen, boasting colonial architecture and a free airport shuttle. Relax in modern rooms and suites, unwind with dining and spa services, and host an event in the versatile venues at our Heredia hotel.',
                },
                {
                  text: 'Experience the city like never before at Costa Rica Marriott Hotel Hacienda Belen. This family-friendly hotel in Belen, Costa Rica offers unmatched access to Juan Santamaría International Airport, the San Antonio district and Poás Volcano National Park.',
                },
                {
                  text: 'Experience the city like never before at Costa Rica Marriott Hotel Hacienda Belen. This family-friendly hotel in Belen, Costa Rica offers unmatched access to Juan Santamaría International Airport, the San Antonio district and Poás Volcano National Park.',
                },
                {
                  text: 'Foster ingenuity at our Heredia hotel in Costa Rica',
                },
                {
                  text: 'Get the most out of your stay in our Heredia accommodations',
                },
                {
                  text: 'Heredia hotel with five restaurants and an on-site spa near Juan Santamaría International Airport.',
                },
              ],
              name: 'Costa Rica Marriott Hotel Hacienda Belen',
            },
            id: 'SJOCR',
            media: {
              photoGallery: {
                golf: null,
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Heredia',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Marriott Vacation Club',
                id: 'MV',
              },
              descriptions: [
                {
                  text: 'Whether your idea of a great vacation is exploring tropical grounds or relaxing in a splendid villa, there’s always space to find your pura vida at Marriott Vacation Club at Los Suenos in Costa Rica. Tucked between Herradura Bay and the lush rainforest of Costa Rica’s Green Coast, our oceanfront vacation ownership resort is set in the Los Suenos Resort & Marina community. Make our guest rooms and one- and two-bedroom villa rentals your home away from home in Los Suenos, CR. Each features interiors inspired by the beauty of Costa Rica, artfully blending rich hues, intricate wood carvings and diverse textures. All villas offer open living and dining spaces, full kitchens and master suites with spa-like bathrooms. Guest rooms feature a queen bed, kitchenette and small seating area. You will also enjoy access to the amenities at the adjacent Los Suenos Marriott Ocean & Golf Resort, including a free-form outdoor pool that overlooks a secluded beach, fitness center and five distinct restaurants.',
                },
                {
                  text: 'Marriott Vacation Club at Los Suenos is an oceanfront resort in Los Suenos, CR. In addition to an outdoor pool, fitness center, tennis courts and miniature golf, guests enjoy access to amenities at the adjacent Los Suenos Marriott Ocean & Golf Resort.',
                },
                {
                  text: 'Marriott Vacation Club at Los Suenos is an oceanfront resort in Los Suenos, CR. In addition to an outdoor pool, fitness center, tennis courts and miniature golf, guests enjoy access to amenities at the adjacent Los Suenos Marriott Ocean & Golf Resort.',
                },
                {
                  text: 'Marriott Vacation Club at Los Suenos is nestled between Herradura Bay and a lush rainforest in Costa Rica. The resort is near popular attractions like Los Sueños Marina Village, Vista Los Sueños Adventure Park, Jaco Beach and Hermosa Beach.',
                },
                {
                  text: 'Marriott Vacation Club at Los Suenos is nestled between Herradura Bay and a lush rainforest in Costa Rica. The resort is near popular attractions like Los Sueños Marina Village, Vista Los Sueños Adventure Park, Jaco Beach and Hermosa Beach.',
                },
                {
                  text: 'High speed Internet, 15 min free long distance calls to the US and international, Use of tennis courts (for 2 hours), 2 tennis rackets and basketball (for 2 hours), Golf practice range with clubs and 2 baskets of balls, Valet Parking - 1 car per room, Floats for pools, Daily use of campfire with marshmallows, 1 Eco tour La Iguana, 30 min use of kayaks, 30 min of use of stand-up paddle board, 30 min of use of bicycles, 1 professional digital photograph per stay',
                },
                {
                  text: 'Experience tropical paradise at our resort in Costa Rica',
                },
                {
                  text: 'Embrace the tranquility of our Costa Rica accommodations',
                },
                {
                  text: 'Family-friendly vacation ownership resort steps from Herradura Beach in Los Suenos, CR.',
                },
              ],
              name: 'Marriott Vacation Club at Los Sueños',
            },
            id: 'SJOMV',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mv-sjomv-mvcisjomvgo-803723:Classic-Hor',
                        },
                        alternateDescription: 'Father teaching son how to swing golf by cart',
                        title: ' Iguana Golf Course',
                      },
                    },
                  ],
                },
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Herradura',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Marriott Hotels & Resorts',
                id: 'MC',
              },
              descriptions: [
                {
                  text: 'One of the most stunning hotels in Playa Herradura, Costa Rica, Los Suenos Marriott Ocean & Golf Resort, is a beachfront haven in a breathtaking natural landscape. Surrounded by the rainforest canopies of Costa Rica´s Green Coast, this luxury oasis sits amid a paradise of natural beauty, endless comfort and adventurous opportunities. Find a refreshing respite in upscale hotel accommodations with breathtaking views and gently swaying hammocks for island-style comfort. Embrace spectacular new experiences, including fishing and hiking through beautiful Carara National Park. Refresh in our sparkling adults-only resort pool and tee off at our on-site golf club. Savor delectable fare at our distinctive restaurants and soothe your senses at our therapeutic spa. A welcoming retreat, rich with a peace-loving culture, our resort is a tranquil sanctuary where the beauty of Herraduras, Costa Rica abounds.',
                },
                {
                  text: 'Los Suenos Marriott Ocean & Golf Resort is a beachfront oasis located in Playa Herradura and the shopping and dining of Los Suenos, Costa Rica. The family-friendly hotel has five on-site restaurants, a championship golf course and rainforest views.',
                },
                {
                  text: 'Los Suenos Marriott Ocean & Golf Resort is a beachfront oasis located in Playa Herradura and the shopping and dining of Los Suenos, Costa Rica. The family-friendly hotel has five on-site restaurants, a championship golf course and rainforest views.',
                },
                {
                  text: 'Located on the beachfront in Herradura, Costa Rica, Los Suenos Marriott Ocean & Golf Resort is perfectly placed near Jaco Beach, Carara National Park and Los Suenos Marina. The hotel is 4.9 kilometers from Los Seunos Adventure Park.',
                },
                {
                  text: 'Located on the beachfront in Herradura, Costa Rica, Los Suenos Marriott Ocean & Golf Resort is perfectly placed near Jaco Beach, Carara National Park and Los Suenos Marina. The hotel is 4.9 kilometers from Los Seunos Adventure Park.',
                },
                {
                  text: 'High speed Internet, 15 min free long distance calls to the US and international, Use of tennis courts (for 2 hours), 2 tennis rackets and basketball (for 2 hours), Golf practice range with clubs and 2 baskets of balls, Valet Parking - 1 car per room, Floats for pools, Daily use of campfire with marshmallows, 1 Eco tour La Iguana, 30 min use of kayaks, 30 min of use of stand-up paddle board, 30 min of use of bicycles, 1 professional digital photograph per stay',
                },
                {
                  text: 'Escape to paradise at our beachfront hotel in Costa Rica',
                },
                {
                  text: 'Immerse yourself in the beauty of Herradura, Costa Rica',
                },
                {
                  text: 'Family-friendly hotel in Herradura, Costa Rica with five restaurants and ocean and rainforest views.',
                },
              ],
              name: 'Los Suenos Marriott Ocean & Golf Resort',
            },
            id: 'SJOLS',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/SJOLS/sjols-aerial-6431-hor-clsc.jpg',
                        },
                        alternateDescription: 'Resort Aerial View',
                        title: 'sjols-aerial-6431',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/SJOLS/sjols-golf-7848-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf',
                        title: 'sjols-golf-7848',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/SJOLS/sjols-sunset-6425-hor-clsc.jpg',
                        },
                        alternateDescription: 'Championship Golf Course',
                        title: 'sjols-sunset-6425',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/SJOLS/sjols-golf-0021-hor-clsc.jpg',
                        },
                        alternateDescription: 'Championship Golf Course',
                        title: 'sjols-golf-0021',
                      },
                    },
                  ],
                },
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Herradura',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Autograph Collection',
                id: 'AK',
              },
              descriptions: [
                {
                  text: "The Buenaventura Golf & Beach Resort Panama, Autograph Collection is a premier beach resort on the shoreline of Panama. Our prime location puts you in close proximity to world-class beaches such as Playa Blanca and El Faro, as well as outdoor adventures, from hiking to four-wheel driving tours. Enjoy a stylish stay in one of our luxury rooms and resort villas, all featuring private balconies, plush mattresses and marble or hardwood floors. Fun for the entire family awaits with five separate pools just steps from the beach. We offer a wide variety of activities, from our exciting high-course rope park to tennis and golf. Take advantage of our 24-hour fitness center, and make an appointment at our on-site spa. Discover an array of restaurants, from a swim-up pool bar to Japanese sushi and teppanyaki restaurant. Don't forget to gather around our legendary Corotu tree, the centerpiece of our hotel. Enjoy a memorable getaway and elevated service at our Buenaventura Beach Resort in Panama.",
                },
                {
                  text: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection offers an elevated experience near fun outdoor activities. Our Buenaventura resort in Panama boasts grand accommodations, pools, a golf course, a spa, dining options and event venues.',
                },
                {
                  text: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection offers an elevated experience near fun outdoor activities. Our Buenaventura resort in Panama boasts grand accommodations, pools, a golf course, a spa, dining options and event venues.',
                },
                {
                  text: 'Explore top attractions from our Riviera Pacifica Panama hotel, The Buenaventura Golf & Beach Resort Panama, Autograph Collection. Stay a short distance from Playa Blanca, Buenaventura Zoo and Tavida. Enjoy activities such as golfing, tennis and hiking.',
                },
                {
                  text: 'Explore top attractions from our Riviera Pacifica Panama hotel, The Buenaventura Golf & Beach Resort Panama, Autograph Collection. Stay a short distance from Playa Blanca, Buenaventura Zoo and Tavida. Enjoy activities such as golfing, tennis and hiking.',
                },
                {
                  text: 'Escape to our Buenaventura, Panama hotel',
                },
                {
                  text: 'Revel in luxury Buenaventura Panama Hotel Rooms',
                },
                {
                  text: 'Buenaventura, Panama hotel with stylish rooms and villas, on-site dining and an 18-hole golf course.',
                },
              ],
              name: 'The Buenaventura Golf & Beach Resort Panama, Autograph Collection',
            },
            id: 'PTYKP',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-club-6809-hor-clsc.jpg',
                        },
                        alternateDescription: 'Buenaventura Golf Club ',
                        title: 'ptykp-club-6809',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6814-hor-clsc.jpg',
                        },
                        alternateDescription: 'Buenaventura Golf Course',
                        title: 'ptykp-golf-6814',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6815-hor-clsc.jpg',
                        },
                        alternateDescription: 'Buenaventura Golf Course',
                        title: 'ptykp-golf-6815',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6812-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'ptykp-golf-6812',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6811-hor-clsc.jpg',
                        },
                        alternateDescription: 'Buenaventura Golf Course',
                        title: 'ptykp-golf-6811',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6810-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'ptykp-golf-6810',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6813-hor-clsc.jpg',
                        },
                        alternateDescription: 'Buenaventura Golf Course',
                        title: 'ptykp-golf-6813',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PTYKP/ptykp-golf-6816-hor-clsc.jpg',
                        },
                        alternateDescription: 'Nicklaus Design Golf Course',
                        title: 'ptykp-golf-6816',
                      },
                    },
                  ],
                },
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Riviera Pacifica',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'The Luxury Collection',
                id: 'LC',
              },
              descriptions: [
                {
                  text: "The Santa Maria, a Luxury Collection Hotel & Golf Resort, Panama City, has prepared a thrilling vacation for you and your loved ones. Our luxury resort in Panama has a pool, three on-site restaurants and a Jack Nicklaus-designed golf course. If you want to venture out, the archaeological sites of Panama Viejo and Costa del Este Panama are close by. We're also located within nine miles of Tocumen International Airport and eight miles from downtown. Settle into a hotel room with an inspiring golf course view and filled with soothing Le Chemin amenities. Spend a day of relaxation at our spa, which offers massages and facials. The infinite beauty of our property makes it perfect for important gatherings, whether wedding- or business-related. Both indoor and outdoor event venues are available, with our Grand Salon being the largest and most luxurious space. Get ready for a greatly satisfying stay at our Panama City, Panama resort. We'll provide outstanding service every step of the way.",
                },
                {
                  text: 'The Santa Maria, a Luxury Collection Hotel & Golf Resort, Panama City, is the perfect getaway. Guests at this luxury resort in Panama will have access to a Jack Nicklaus-designed golf course as well as 3 on-site restaurants and a tranquilizing spa.',
                },
                {
                  text: 'The Santa Maria, a Luxury Collection Hotel & Golf Resort, Panama City, is the perfect getaway. Guests at this luxury resort in Panama will have access to a Jack Nicklaus-designed golf course as well as 3 on-site restaurants and a tranquilizing spa.',
                },
                {
                  text: 'Our luxury resort in Panama is surrounded by a Jack Nicklaus golf course. The Santa Maria, a Luxury Collection Hotel & Golf Resort, Panama City, nears Tocumen International Airport, Costa del Este Panama, the Casco Antiguo and San Blas Islands.',
                },
                {
                  text: 'Our luxury resort in Panama is surrounded by a Jack Nicklaus golf course. The Santa Maria, a Luxury Collection Hotel & Golf Resort, Panama City, nears Tocumen International Airport, Costa del Este Panama, the Casco Antiguo and San Blas Islands.',
                },
                {
                  text: 'Book your adventure at our luxury resort in Panama',
                },
                {
                  text: 'Lounge and relax in our Panama City, Panama suites',
                },
                {
                  text: 'Five-star hotel in Panama City, Panama with Panamanian cuisine, a spa and exclusive meeting venues.',
                },
              ],
              name: 'The Santa Maria, a Luxury Collection Hotel & Golf Resort, Panama City',
            },
            id: 'PTYLC',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/PTYLC/ptylc-golf-course-7916-hor-clsc.jpg',
                        },
                        alternateDescription: 'Sunset Golf Course',
                        title: 'ptylc-golf-course-7916',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/PTYLC/ptylc-golf-course-7334-hor-clsc.jpg',
                        },
                        alternateDescription: 'Golf Course',
                        title: 'ptylc-golf-course-7334',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/lc-ptylc-golf-course-24362:Classic-Hor',
                        },
                        alternateDescription: 'A Gorgeous 18-Hole Course',
                        title: 'Golf Course',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/lc-ptylc-santa-maria-golf-club--19787:Classic-Hor',
                        },
                        alternateDescription: 'Santa Maria Golf Club ',
                        title: 'Santa Maria Golf Club ',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/lc-ptylc-golf-course-18233:Classic-Hor',
                        },
                        alternateDescription: 'A Gorgeous 18-Hole Course',
                        title: 'Golf Course',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/lc-ptylc-golf-exterior-34297-86406:Classic-Hor',
                        },
                        alternateDescription: 'Golf',
                        title: 'Golf exterior 34297',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/lc-ptylc-golf-exterior-view-20661-58063:Classic-Hor',
                        },
                        alternateDescription: 'Golf exterior view',
                        title: 'Golf exterior view 20661',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/lc-ptylc-golf-exterior-view-23411-77668:Classic-Hor',
                        },
                        alternateDescription: 'Golf exterior view ',
                        title: 'Golf exterior view 23411',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/lc-ptylc-golf-exterior-38155-64424:Classic-Hor',
                        },
                        alternateDescription: 'Golf course exterior',
                        title: 'Golf exterior-38155',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/lc-ptylc-golf-exterior-22182-10219:Classic-Hor',
                        },
                        alternateDescription: 'Golf',
                        title: 'Golf exterior-22182',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/lc-ptylc-golf-course-view-41732-90661:Classic-Hor',
                        },
                        alternateDescription: 'Golf Course View',
                        title: 'Golf Course View 41732',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/ptylc-golf-course-7919:Classic-Hor',
                        },
                        alternateDescription: 'Aerial Golf Course',
                        title: 'ptylc-golf-course-7919',
                      },
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/lc-ptylc-golf-course-aerial-35514-60756:Classic-Hor',
                        },
                        alternateDescription: 'Approved Approved Approved Approved Approved Appro',
                        title: 'Golf course aerial-35514',
                      },
                    },
                  ],
                },
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Panama City',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Autograph Collection',
                id: 'AK',
              },
              descriptions: [
                {
                  text: "New York Marriott Marquis is a contemporary hotel located on Broadway, in the heart of NYC's Times Square. Discover spacious accommodations and thoughtfully appointed amenities, including over 100,000 square feet of event space.",
                },
                {
                  text: 'This is th best property',
                },
                {
                  text: 'This is th best property',
                },
                {
                  text: 'This is the best sea view location in whole region',
                },
                {
                  text: 'This is the best sea view location in whole region',
                },
                {
                  text: 'Not Include Per Person Charge',
                },
                {
                  text: 'Nearest Way!',
                },
                {
                  text: 'No header tag required',
                },
                {
                  text: 'Nykaa',
                },
                {
                  text: 'This property is constructed using modern design, latest technology',
                },
              ],
              name: 'Sheraton Shanghai Hongqiao Hotel',
            },
            id: 'AQPPP',
            media: {
              photoGallery: {
                golf: null,
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Texas',
              },
            },
          },
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Autograph Collection',
                id: 'AK',
              },
              descriptions: [
                {
                  text: "New York Marriott Marquis is a contemporary hotel located on Broadway, in the heart of NYC's Times Square. Discover spacious accommodations and thoughtfully appointed amenities, including over 100,000 square feet of event space.",
                },
                {
                  text: 'This is th best property',
                },
                {
                  text: 'This is th best property',
                },
                {
                  text: 'This is the best sea view location in whole region',
                },
                {
                  text: 'This is the best sea view location in whole region',
                },
                {
                  text: 'Not Include Per Person Charge',
                },
                {
                  text: 'Nearest Way!',
                },
                {
                  text: 'No header tag required',
                },
                {
                  text: 'Nykaa',
                },
                {
                  text: 'This property is constructed using modern design, latest technology',
                },
              ],
              name: 'Sheraton Shanghai Hongqiao Hotel',
            },
            id: 'AQAAI',
            media: {
              photoGallery: {
                golf: null,
              },
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Texas',
                },
                city: 'Texas',
              },
            },
          },
        },
      ],
      facets: [
        {
          buckets: [
            {
              code: 'beach',
              label: 'Beach',
              description: 'Beach',
              count: 4,
            },
            {
              code: 'family',
              label: 'Family travel',
              description: 'Family travel',
              count: 4,
            },
            {
              code: 'spa',
              label: 'Full service spa',
              description: 'Full service spa',
              count: 9,
            },
            {
              code: 'golf',
              label: 'Golf',
              description: 'Golf',
              count: 9,
            },
            {
              code: 'tennis',
              label: 'Tennis',
              description: 'Tennis',
              count: 5,
            },
          ],
          type: {
            code: 'activities-on-site',
            label: 'Activities',
            description: 'Activities On-site',
            count: 5,
          },
        },
        {
          buckets: [
            {
              code: 'TX',
              label: 'Texas',
              description: 'Texas',
              count: 2,
            },
          ],
          type: {
            code: 'states',
            label: 'States / Provinces',
            description: 'States / Provinces',
            count: 1,
          },
        },
      ],
    },
  },
};

export const getCourseLocationsMock = {
  request: {
    query: golfCoursePropertiesDetails,
    variables: {
      search: {
        destination: 'United states',
        facets: {
          terms: [
            {
              type: 'ACTIVITIES',
              dimensions: ['golf'],
            },
            {
              type: 'STATES',
              dimensions: [''],
            },
          ],
        },
      },
      limit: 18,
      offset: 0,
    },
  },
  result: golfCourseLocationsMock,
};

export const errorMock = {
  request: {
    query: golfCoursePropertiesDetails,
    variables: {
      search: {
        destination: 'United states',
        facets: {
          terms: [
            {
              type: 'ACTIVITIES',
              dimensions: ['golf'],
            },
            {
              type: 'STATES',
              dimensions: [''],
            },
          ],
        },
      },
      limit: 18,
      offset: 0,
    },
  },
  error: new Error('Network Error'),
};
