/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
export interface searchFilterState {
  hours: any[];
  cuisine: any[];
  distance: any[];
  seating: any[];
  bonvoyMembers: any[];
  brands: any[];
  venueType: any[];
  dietaryOptions: any[];
  experiences: any[];
  appliedFilter: any[];
  realTimeFilter: {
    terms: any[];
    ranges: any[];
  };
  city: any[];
  stateProvinceDescription: any[];
  countryDescription: any[];
  country: any[];
  statesData: any[];
  hourSelection: (selectedData: any) => void;
  cuisineSelection: (selectedData: any) => void;
  distanceSelection: (selectedData: any) => void;
  seatingSelection: (selectedData: any) => void;
  bonvoySelection: (selectedData: any) => void;
  brandSelection: (selectedData: any) => void;
  venueTypeSelection: (selectedData: any) => void;
  dietaryOptionsSelection: (selectedData: any) => void;
  experiencesSelection: (selectedData: any) => void;
  citySelection: (selectedData: any) => void;
  stateProvinceDescSelection: (selectedData: any) => void;
  countryDescSelection: (selectedData: any) => void;
  countrySelection: (selectedData: any) => void;
  statesSelection: (selectedData: any) => void;
  clearAllSelections: () => void;
  setFilter: (data: any) => void;
  resetFilters: () => void;
  filterButtonType: string;
  setFilterButtonType: (filterType: any) => void;
  setFilterRealTimeTerms: (selectedData: any) => void;
  setFilterRealTimeRanges: (selectedData: any) => void;
  isNewFacetsLoading: boolean;
  setIsNewFacetsLoading: (isLoading: boolean) => void;
}

const initialState: searchFilterState = {
  hours: [],
  cuisine: [],
  distance: [],
  seating: [],
  bonvoyMembers: [],
  brands: [],
  venueType: [],
  dietaryOptions: [],
  experiences: [],
  city: [],
  stateProvinceDescription: [],
  countryDescription: [],
  country: [],
  statesData: [],
  appliedFilter: [],
  realTimeFilter: {
    terms: [],
    ranges: [],
  },
  hourSelection: () => {},
  cuisineSelection: () => {},
  distanceSelection: () => {},
  seatingSelection: () => {},
  brandSelection: () => {},
  bonvoySelection: () => {},
  venueTypeSelection: () => {},
  dietaryOptionsSelection: () => {},
  experiencesSelection: () => {},
  citySelection: () => {},
  stateProvinceDescSelection: () => {},
  countryDescSelection: () => {},
  countrySelection: () => {},
  statesSelection: () => {},
  clearAllSelections: () => {},
  setFilter: () => {},
  resetFilters: () => {},
  filterButtonType: ' ',
  setFilterButtonType: () => {},
  setFilterRealTimeTerms: () => {},
  setFilterRealTimeRanges: () => {},
  isNewFacetsLoading: false,
  setIsNewFacetsLoading: () => {},
};

const searchFilterStore = create<searchFilterState>(set => ({
  ...initialState,
  hourSelection: (selectedData: any) => set(state => ({ ...state, hours: selectedData })),
  cuisineSelection: (selectedData: any) => set(state => ({ ...state, cuisine: selectedData })),
  distanceSelection: (selectedData: any) => set(state => ({ ...state, distance: selectedData })),
  seatingSelection: (selectedData: any) => set(state => ({ ...state, seating: selectedData })),
  bonvoySelection: (selectedData: any) => set(state => ({ ...state, bonvoyMembers: selectedData })),
  brandSelection: (selectedData: any) => set(state => ({ ...state, brands: selectedData })),
  venueTypeSelection: (selectedData: any) => set(state => ({ ...state, venueType: selectedData })),
  dietaryOptionsSelection: (selectedData: any) => set(state => ({ ...state, dietaryOptions: selectedData })),
  experiencesSelection: (selectedData: any) => set(state => ({ ...state, experiences: selectedData })),
  citySelection: (selectedData: any) => set(state => ({ ...state, city: selectedData })),
  stateProvinceDescSelection: (selectedData: any) =>
    set(state => ({ ...state, stateProvinceDescription: selectedData })),
  countryDescSelection: (selectedData: any) => set(state => ({ ...state, countryDescription: selectedData })),
  countrySelection: (selectedData: any) => set(state => ({ ...state, country: selectedData })),
  statesSelection: (selectedData: any) => set(state => ({ ...state, statesData: selectedData })),
  clearAllSelections: () => set(initialState),
  setFilter: (selectedFilter: any) => set(state => ({ ...state, appliedFilter: selectedFilter })),
  resetFilters: () => {
    set(state => ({
      ...state,
      hours: [],
      cuisine: [],
      distance: [],
      seating: [],
      bonvoyMembers: [],
      brands: [],
      venueType: [],
      dietaryOptions: [],
      experiences: [],
      city: [],
      stateProvinceDescription: [],
      countryDescription: [],
      country: [],
      statesData: [],
    }));
  },
  setFilterButtonType: (filterType: any) => set(state => ({ ...state, filterButtonType: filterType })),
  setFilterRealTimeTerms: (selectedFilter: any) =>
    set(state => ({ ...state, realTimeFilter: { ...state.realTimeFilter, terms: selectedFilter } })),
  setFilterRealTimeRanges: (selectedFilter: any) =>
    set(state => ({ ...state, realTimeFilter: { ...state.realTimeFilter, ranges: selectedFilter } })),
  setIsNewFacetsLoading: (isLoading: boolean) => set({ isNewFacetsLoading: isLoading }),
}));

export default searchFilterStore;
