import { useContext } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { BonvoyMemberBannerProps } from './index';
import { BannerLayered, BannerLayeredProps } from '@marriott/mi-ui-library';
import { PageParamsContext } from '../../modules/context/PageContext';
import { StyledBonvoyMember } from './BonvoyMemberBanner.styles';
import { TRACKING_CONSTANT, PAGES } from '../../../src/modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const BonvoyMemberBanner = (props: BonvoyMemberBannerProps) => {
  const { userId, currentPage } = useContext(PageParamsContext);
  const { CITY_OFFERS_COMPONENT, CITY_OFFERS_RNB, EXTERNAL_LINK, GOLF_PAGE_BONVOYBANNER } = TRACKING_CONSTANT;
  let custom_click_city = '';
  let custom_click_golf_overview = '';

  if (CITY_OFFERS_COMPONENT && CITY_OFFERS_RNB && EXTERNAL_LINK) {
    custom_click_city = `${CITY_OFFERS_COMPONENT}|${CITY_OFFERS_RNB}|${EXTERNAL_LINK}`;
  }

  if (GOLF_PAGE_BONVOYBANNER && (props?.primaryCtaText || props?.secondaryCtaText) && EXTERNAL_LINK) {
    custom_click_golf_overview = `${GOLF_PAGE_BONVOYBANNER}|${
      props?.primaryCtaText || props?.secondaryCtaText
    }|${EXTERNAL_LINK}`;
  }

  const basicProps = {
    headerTag: 'h3' as BannerLayeredProps['headerTag'],
    ctaType: 'primarybutton' as BannerLayeredProps['ctaType'],
    position: props?.position,
    styleclass: props?.styleclass,
    tertiaryLinkText: '',
    tertiaryLinkOpenInNewTab: false,
    trackingProperties: props?.trackingProperties,
    custom_click_track_value_primary:
      currentPage === PAGES.CITYPAGE
        ? custom_click_city
        : currentPage === PAGES.GOLF_OVERVIEW_PAGE
        ? custom_click_golf_overview
        : '',
  };

  const unSignedUserProps = {
    ...basicProps,
    header: props?.header,
    description: props?.description,
    primaryCtaText: props?.primaryCtaText,
    primaryCtaLink: props?.primaryCtaLink,
    primaryCtaOpenInNewTab: props?.primaryCtaOpenInNewTab,
    secondaryCtaText: props?.secondaryCtaText,
    secondaryCtaLink: props?.secondaryCtaLink,
    secondaryCtaOpenInNewTab: props?.secondaryCtaOpenInNewTab,
    dynamicMediaMobile: props?.dynamicMedia,
    dynamicMedia: props?.dynamicMedia,
    iconPath: props?.iconPath,
    iconText: props?.iconText,
    model: props?.model,
  };

  const signedUserProps = {
    ...basicProps,
    header: props?.headerSignIn,
    description: props?.descriptionSignIn,
    primaryCtaText: props?.primaryCtaTextSignIn,
    primaryCtaLink: props?.primaryCtaLinkSignIn,
    primaryCtaOpenInNewTab: props?.primaryCtaOpenInNewTabSignIn,
    secondaryCtaText: '',
    secondaryCtaLink: '',
    secondaryCtaOpenInNewTab: false,
    dynamicMediaMobile: props?.dynamicMediaSignIn,
    dynamicMedia: props?.dynamicMediaSignIn,
    iconPath: props?.iconPathSignIn,
    iconText: props?.iconTextSignIn,
    model: props?.model,
  };

  const bannerLayerProps = userId ? signedUserProps : unSignedUserProps;
  return (
    <StyledBonvoyMember
      styleClass={props?.styleclass}
      data-testid="bannerwithbutton"
      data-component-name="o-rnb-bannerwithbutton"
    >
      <BannerLayered {...bannerLayerProps} />
    </StyledBonvoyMember>
  );
};

export const BonvoyMemberBannerConfig = {
  emptyLabel: 'bannerwithbutton',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/bannerwithbutton`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BonvoyMemberBannerEditable = (props: any) => (
  <EditableComponent config={BonvoyMemberBannerConfig} {...props}>
    <BonvoyMemberBanner {...props} />
  </EditableComponent>
);
