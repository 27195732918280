import { useEffect, useState } from 'react';
import { ResponsiveGrid, EditableComponent } from '@adobe/aem-react-editable-components';
import { CardLayered, Text, Types } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useGetBreakpoint } from '../../modules/utils/helpers';
import { DualCardContainerProps } from './index';
import { StyledDualCardContainer } from './DualCardContainer.styles';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const DualCardContainer = (props: DualCardContainerProps) => {
  const { totalNumberOfCards, cqItems, isAuthorMode, itemPath, pagePath, title, styleclass } = props;
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Author Card
  const cardContainer = (index: number) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardComponent = (cardName: string, jsonData: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          return <CardLayered {...item} />;
        }
      }
    }
    return null;
  };

  return totalNumberOfCards?.length > 0 ? (
    <StyledDualCardContainer
      className={clsx(
        {
          'm-container-fullbleed': styleclass?.includes('fullbleed'),
        },
        'py-5',
        styleclass
      )}
    >
      <div className="container">
        <Text
          customClass={'t-title-s mb-3'}
          copyText={title}
          fontSize={Types.size.large}
          element={Types.tags.paragraph}
        />

        <div
          className={clsx(isMobileViewPort ? 'flex-column' : 'flex-row', 'd-flex dual-card-container')}
          data-testid="dual-card-container"
        >
          {!isAuthorMode && totalNumberOfCards?.map((cardName: string) => cardComponent(cardName, cqItems))}
          {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => cardContainer(i))}
        </div>
      </div>
    </StyledDualCardContainer>
  ) : null;
};

export const DualCardContainerConfig = {
  emptyLabel: 'golfcontainer',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/golf-container`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DualCardContainerEditable = (props: any) => (
  <EditableComponent config={DualCardContainerConfig} {...props}>
    <DualCardContainer {...props} />
  </EditableComponent>
);
