import { Button, Icon, RichText, Text, Types } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledGolfContactDetails } from './GolfContactDetails.styles';
import clsx from 'clsx';
import { TARGET_BLANK } from '../../modules/utils/constants/constants';
import { useEffect, useState } from 'react';
import { useGetBreakpoint } from '../../modules/utils/helpers';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export type GolfContactDetailsProps = {
  bodyText?: string;
  addressText?: string;
  contactText?: string;
  addressLink?: string;
  styleclass?: string;
};

export const GolfContactDetails = (props: GolfContactDetailsProps) => {
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledGolfContactDetails className={clsx('d-flex flex-column', props?.styleclass || 'alternate')}>
      {props?.bodyText && (
        <Text copyText={props?.bodyText} fontSize={Types.size.medium} element={Types.tags.div} customClass="mb-2" />
      )}
      {(props?.addressText || props?.contactText) && (
        <div className={clsx(isMobileViewPort ? 'flex-column' : 'flex-row', 'd-flex contact-details')}>
          {props?.addressText && (
            <p className="d-flex t-font-s m-0">
              <Icon iconClass="icon-location" ariaLabel="icon-location" />
              <span className="d-inline-flex">
                <Button
                  isLink={true}
                  href={props?.addressLink}
                  aria-label={props?.addressText}
                  target={TARGET_BLANK}
                  className="ml-2 contact-details__link"
                  testId="location-details"
                >
                  {props?.addressText}
                </Button>
              </span>
            </p>
          )}
          {props?.contactText && (
            <p className="d-flex t-font-s m-0">
              <Icon iconClass="icon-phone" ariaLabel="icon-phone" />
              <span className="d-inline-flex">
                <Button
                  isLink={true}
                  href={`tel:${props?.contactText}`}
                  aria-label={props?.contactText}
                  className="ml-2 contact-details__link"
                  testId="phone-number"
                >
                  {props?.contactText}
                </Button>
              </span>
            </p>
          )}
        </div>
      )}
    </StyledGolfContactDetails>
  );
};

export const GolfContactDetailsConfig = {
  emptyLabel: 'golfcontact',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/golfcontact`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GolfContactDetailsEditable = (props: any) => (
  <EditableComponent config={GolfContactDetailsConfig} {...props}>
    <GolfContactDetails {...props} />
  </EditableComponent>
);
