/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

export interface golfCourseLocationsStore {
  golfCourseLocationsError: boolean;
  golfCourseLocationsList: any;
  golfCourseLocationsLoading: boolean;
  terms: any;
  scrollPosition: number;
  setGolfCourseLocationsList: (data: any, error: boolean, loading: boolean) => void;
  setTerms: (terms: any) => void;
  setScrollPosition: (position: number) => void;
}

export const initialState: golfCourseLocationsStore = {
  golfCourseLocationsError: false,
  golfCourseLocationsList: null,
  golfCourseLocationsLoading: false,
  terms: [],
  scrollPosition: 0,
  setGolfCourseLocationsList: () => {},
  setTerms: () => {},
  setScrollPosition: () => {},
};

export const useStore: StateCreator<golfCourseLocationsStore> = set => {
  return {
    ...initialState,

    setGolfCourseLocationsList: (data: any, error: any, loading: boolean) => {
      set({ golfCourseLocationsList: data, golfCourseLocationsError: error, golfCourseLocationsLoading: loading });
    },

    setTerms: terms => {
      set({ terms });
    },

    setScrollPosition: position => {
      set({ scrollPosition: position });
    },
  };
};

export const golfCourseLocationsStore = createAppStore(useStore, {
  usePersistentStore: false,
});
