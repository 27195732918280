import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledGolfContactDetails = styled.div`
  background: inherit;
  padding-bottom: ${toRem(48)};

  .contact-details {
    row-gap: ${toRem(8)};

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      column-gap: ${toRem(24)};
    }

    &__link {
      text-decoration: underline;
    }
  }
`;
