import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledFeaturedOutlets = styled.div<{ variation?: string }>`
  background: ${props => (props?.variation === 'culinaryView' ? 'inherit' : `${baseVariables.color.neutral20}`)};

  .glide__track {
    overflow: hidden;
  }

  .outer-glide {
    list-style-type: none;
  }

  .property-card__details-container {
    height: calc(100% - 50%);
  }

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    .property-card__details-container {
      height: inherit;
    }
  }

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}) {
    .carouselControlType2 > .glide__arrows
    {
      .glide__arrow--left > .icon-arrow-left {
        &::before{
          content: "\\e922";
          cursor: pointer;
        }
      }
      .glide__arrow--right > .icon-arrow-right {
        &::before{
          content: "\\e921";
          cursor: pointer;
        }
      }
    }

    .m-link-tertiary-button {
      margin-left: ${toRem(26)};
    }

    .disabled-rtl-arrow {
      opacity: 0.2;
      cursor: not-allowed;
    }

    .enabled-rtl-arrow {
      cursor: pointer !important;
    }
    
    .ru-locale-specific {
      margin: 2rem;
    }

    .glide__arrow--disabled {
      opacity: unset;
    }

    .glide__arrow--disabled:hover {
      opacity: unset;
      text-decoration: underline;
    }

    .m-link-tertiary-button::after
    {
      content: "\\e929" !important;
      font-size: ${toRem(16)};
      padding-right: ${toRem(8)};
      line-height: ${toRem(18)};
    }

    .property-card__details-container {
      text-align: right;
    }

    .property-card__title {
      padding-left: ${toRem(24)} !important;
      padding-right: ${toRem(4)} !important;
    }

    .property-card__address, .icon-dining {     
      padding-left: 0 !important;
      padding-right: ${toRem(16)} !important;
    }
    
    .property-card__details-container_desc, .property-card__details-container_footer, .property-card__eyebrow {
      padding-left: ${toRem(24)} !important;
      padding-right: ${toRem(16)} !important;
    }

    .property-card__details-container_footer_link {
      margin-left: 0 !important;
      margin-right: ${toRem(24)} !important;
    }

    .culinary-view-container .property-card__tertiary-link {
      margin-left: ${toRem(27)} !important;
      margin-right: ${toRem(16)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(32)} !important;
      }
    }

    .featured-outlet .property-card__tertiary-link {
      margin-left: ${toRem(27)} !important;
      margin-right: ${toRem(16)} !important;
      padding-left: ${toRem(24)} !important;
      padding-right: 0 !important;

      &::after {
        content: "\\e927";
        padding-left: 0 !important;
        padding-right: ${toRem(8)} !important;
      }
    }

    .img-carousel__container .icon-arrow-right, .icon-arrow-left {
      &::before {
        left: 50% !important; 
        right: 20% !important;
      }
    }
  }
`}
`;
