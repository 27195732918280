/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  golfActivitiesDimension,
  golfActivitiesType,
  GolfLocationsPageSize,
  golfStateFilterType,
  IMAGE_URL_DOMAIN,
  RnBFILTERS,
} from '../../modules/utils/constants/constants';
import clsx from 'clsx';
import {
  GlobalStyle,
  StyledGolfLocationContainer,
  StyledVerticalGolfCards,
} from './GolfCourseLocationsContainer.styles';
import { PageParamsContext } from '../../modules/context/PageContext';
import { useApolloClient } from '@apollo/client';
import { golfCoursePropertiesDetails } from '@marriott/mi-rnb-graphql';
import Cookies from 'js-cookie';
import { golfCourseLocationsMock } from './__mocks__/mockModel';
/* eslint-disable @nx/enforce-module-boundaries */
import { PropertyCard } from '@marriott/mi-shop-components';
import { GolfCourseLocationsContainerProps } from './GolfCourseLocationsContainer.types';
import { TabComponent } from '@marriott/mi-ui-library';
import { PaginationPanel } from '../../molecules/Pagination';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { SearchAllFilters } from '../SearchResults/SearchAllFilters';
import { getProcessedFilterData, useGetBreakpoint } from '../../modules/utils/helpers';
import { golfCourseLocationsStore } from '../../modules/store/golfCourseLocationsStore';
import searchFilterStore, { searchFilterState } from '../../modules/store/store';
import { FacetsType, orderList } from '../CampaignOffersCardContainer/CampaignOffersCardContainer.types';
import { HideGlobalHeader } from '../CampaignOffersCardContainer/CampaignOffersCardContainer.styles';
import { terms } from '../SearchResults/SearchAllFilters/SearchAllFilters.types';
import { TRACKING_CONSTANT } from '../../modules/utils/constants/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const GolfCourseLocationsContainer = (props: GolfCourseLocationsContainerProps) => {
  const { currentPage: currentPageFromContext, currentLocale } = useContext(PageParamsContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();
  const [selectedTab, setSelectedTab] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');
  const [currentPage, setCurrentPage] = useState(1);
  const paginationChange = useStore((state: searchResultsState) => state.paginationChange);
  const pageNumber = useStore((state: searchResultsState) => state.pageNumber);
  const setPaginationChange = useStore((state: searchResultsState) => state.setPaginationChange);
  const setPageNumber = useStore((state: searchResultsState) => state.setPageNumber);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const term = golfCourseLocationsStore(state => state.terms);
  const setTerms = golfCourseLocationsStore((state: { setTerms: any }) => state.setTerms);
  const queryParam = useStore((state: searchResultsState) => state.queryParam);
  const setQueryParam = useStore((state: searchResultsState) => state.setQueryParam);
  let filterUpdateData = useStore((state: searchResultsState) => state.facetsData);
  const setFacetsData = useStore((state: searchResultsState) => state.setFacetsData);
  const statesData = searchFilterStore((state: searchFilterState) => state.statesData);
  const stateSelection = searchFilterStore((state: searchFilterState) => state.statesSelection);
  const [hideHeader, setHideHeader] = useState(false);
  const scrollPosition = golfCourseLocationsStore(state => state.scrollPosition);
  const setScrollPosition = golfCourseLocationsStore(state => state.setScrollPosition);
  const golfCourseLocationsList = golfCourseLocationsStore(state => state.golfCourseLocationsList);
  const setGolfCourseLocationsList = golfCourseLocationsStore(state => state.setGolfCourseLocationsList);
  const golfCourseLocationsLoading = golfCourseLocationsStore(state => state.golfCourseLocationsLoading);
  const updatedTerms: terms[] = [];
  const { setFilter } = searchFilterStore();
  const { GOLF_COURSE_LOCATION_CARD, GOLF_LOCATIONS_PAGINATION, EXTERNAL_LINK } = TRACKING_CONSTANT;

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    function handleScroll() {
      const scrollPos = window?.scrollY;
      if (scrollPosition !== scrollPos) setHideHeader(false);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPosition]);

  useEffect(() => {
    setQueryParam(window.location.search);
  }, []);

  useEffect(() => {
    setFacetsPayload();
  }, [queryParam, pageNumber]);

  useEffect(() => {
    if (!props?.isAuthorMode) {
      setSelectedTab(props?.tabList[0]?.tabValue);
    }
  }, [props?.tabList]);

  useEffect(() => {
    if (props?.isAuthorMode) {
      setGolfCourseLocationsList(golfCourseLocationsMock.data.searchPropertiesByDestination, false, false);
      setFacetsData(golfCourseLocationsMock.data.searchPropertiesByDestination.facets);
    }
  }, [props?.isAuthorMode]);

  useEffect(() => {
    if (!props?.isAuthorMode && selectedTab) {
      let updatedTerms = [
        ...term,
        {
          type: golfActivitiesType,
          dimensions: [golfActivitiesDimension],
        },
      ];

      if (updatedTerms?.some(term => term.type.toUpperCase() === golfStateFilterType.toUpperCase())) {
        updatedTerms = updatedTerms.map(facetTerm => {
          if (facetTerm?.type?.toUpperCase() === golfStateFilterType.toUpperCase()) {
            const updatedFacetTerm = {
              ...facetTerm,
              type: facetTerm.type.toUpperCase(),
            };
            return updatedFacetTerm;
          } else return facetTerm;
        });
      } else {
        updatedTerms = [
          ...updatedTerms,
          {
            type: golfStateFilterType.toUpperCase(),
            dimensions: [],
          },
        ];
      }

      getGolfCourseLocations({
        variables: {
          search: {
            destination: selectedTab,
            facets: {
              terms: updatedTerms,
            },
          },
          limit: GolfLocationsPageSize,
          offset: pageNumber === 1 ? 0 : (pageNumber - 1) * GolfLocationsPageSize,
          version: 'V2',
        },
        context: {
          headers: {
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
    }
  }, [term, pageNumber, selectedTab]);

  useEffect(() => {
    if (!paginationChange) {
      setCurrentPage(1);
      setPageNumber(1);
    }
  }, [pageNumber, golfCourseLocationsList]);

  const onTabChange = (selectedTabValue: string) => {
    setSelectedTab(selectedTabValue);
    setCurrentPage(1);
    setPageNumber(1);
    setFilter([]);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(RnBFILTERS.states);

    const queryString = searchParams.toString();
    if (queryString) window.history.pushState({}, '', `?${queryString}`);
    else window.history.pushState(null, '', window.location.pathname);
    setQueryParam(queryString);
  };

  const onPageItemClick = (page: number): void => {
    setPaginationChange(true);
    setCurrentPage(page);
    setPageNumber(page);
    scrollToSection('golfLocationsContainer');
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    const headerAccBtn = document.getElementsByClassName('m-header standard');
    section?.scrollIntoView(true);
    if (headerAccBtn?.length > 0) {
      setHideHeader(true);
      setScrollPosition(window?.scrollY);
    } else {
      setHideHeader(false);
      setScrollPosition(0);
    }
  };

  const setFacetsPayload = () => {
    const query = new URLSearchParams(queryParam);
    const routeKeys: string[] = [];
    query.forEach((_value, key) => {
      routeKeys.push(key);
    });
    const filteredArray = routeKeys.filter((value: string) => {
      return props?.filtersOrderList?.some((obj: orderList) => obj?.filterName === value);
    });
    stateSelection([]);

    if (filteredArray.length > 0) {
      filteredArray.forEach((filter: string) => {
        storeUpdater(filter);
      });
    } else setTerms([]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateQueryParams = (filterName: string, filterData: FacetsType[], selectedVal: any, facetsVal: any) => {
    if (filterData?.length > 0) {
      if (filterName === RnBFILTERS.states) {
        const getSelectedFilter = filterData.filter((element: FacetsType) => selectedVal.includes(element.code));
        const filterLabels = getSelectedFilter.map((filterName: FacetsType) => filterName.code);
        facetsVal['dimensions'] = filterLabels;
      } else {
        const getSelectedFilter = filterData.filter((element: FacetsType) => selectedVal.includes(element.label));
        const filterLabels = getSelectedFilter?.map((filterName: FacetsType) => filterName.label);
        facetsVal['dimensions'] = filterLabels;
      }
    } else {
      facetsVal['dimensions'] = [...selectedVal];
    }
    updatedTerms.push(facetsVal);
    setTerms(updatedTerms);
  };

  const storeUpdater = (filter: string) => {
    const query = new URLSearchParams(window.location.search);
    const facetsVal = { type: '', dimensions: [] };
    const values: string | null = query?.get(filter) ? query?.get(filter) : '';
    let selectedVal: string[] | undefined = [];
    selectedVal = values?.split(',');

    facetsVal.type = filter;
    switch (facetsVal.type) {
      case 'states':
        generateQueryParams('states', statesData, selectedVal, facetsVal);
        break;
    }
  };

  const updateStoreData = (
    name: string,
    filterName: string,
    storeFilterUpdater: (selectedFilter: FacetsType[]) => void,
    query: URLSearchParams
  ) => {
    const filterData = getProcessedFilterData(
      filterUpdateData?.facets,
      name,
      props,
      currentLocale ? currentLocale : 'en-US'
    );
    const filterValues: string | null = query.get(filterName) ? query.get(filterName) : '';
    if (filterValues) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let selectedFilter: any = [];
      selectedFilter = filterValues.split(',');
      const selectedFilterArr: FacetsType[] = [];
      if (filterName === RnBFILTERS.states) {
        filterData.forEach((item: FacetsType) => {
          if (selectedFilter.includes(item.code)) selectedFilterArr.push(item);
        });
      } else {
        filterData.forEach((item: FacetsType) => {
          if (selectedFilter.includes(item.label)) selectedFilterArr.push(item);
        });
      }
      storeFilterUpdater(selectedFilterArr);
    }
  };

  // UXL call to get Search Results
  const golfCourseLocationClient = useApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function getGolfCourseLocations(inputValues: any) {
    setGolfCourseLocationsList(null, false, true);
    let golfCourseLocation = null;

    try {
      const { data } = await golfCourseLocationClient.query({
        fetchPolicy: 'no-cache',
        query: golfCoursePropertiesDetails,
        errorPolicy: 'all',
        ...inputValues,
      });
      if (data && data?.searchPropertiesByDestination && data?.searchPropertiesByDestination?.edges?.length > 0)
        golfCourseLocation = data?.searchPropertiesByDestination;
    } catch (error) {
      setGolfCourseLocationsList(null, true, false);
    } finally {
      if (golfCourseLocation?.edges?.length > 0) {
        filterUpdateData = golfCourseLocation;
        setGolfCourseLocationsList(golfCourseLocation, false, false);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const facets = golfCourseLocation?.facets?.map((facet: any) => {
          return {
            ...facet,
            buckets: facet?.buckets?.filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (bucket: any) => bucket?.label !== '' && bucket?.label !== null && bucket?.label !== undefined
            ),
          };
        });
        setFacetsData(facets);
        if (filterUpdateData?.facets?.length > 0) {
          const query = new URLSearchParams(queryParam);
          updateStoreData('states', 'states', stateSelection, query);
        }
      } else {
        setGolfCourseLocationsList(null, true, false);
      }
    }
  }

  const getPropertyName = (propertyDetails: any) => {
    const titleDetails = {
      title: propertyDetails?.node?.basicInformation?.name,
      titleAriaLabel: propertyDetails?.node?.basicInformation?.name,
    };
    return titleDetails;
  };

  const getPropertyImages = (propertyDetails: any) => {
    const images: { defaultImageUrl: string } = {
      defaultImageUrl: '',
    };
    const courseImages = [];
    if (
      propertyDetails?.node?.media?.photoGallery?.golf &&
      propertyDetails?.node?.media?.photoGallery?.golf?.edges?.length > 0
    ) {
      propertyDetails?.node?.media?.photoGallery?.golf?.edges?.forEach((image: any) => {
        if (image?.node?.imageUrls?.classicHorizontal) {
          const imageUrl = `${IMAGE_URL_DOMAIN}${image?.node?.imageUrls?.classicHorizontal}?downsize=228:*`;
          images['defaultImageUrl'] = imageUrl;
          courseImages.push({ ...images });
        }
      });
    } else if (
      propertyDetails?.node?.media?.photoGallery?.hotelView &&
      propertyDetails?.node?.media?.photoGallery?.hotelView?.edges?.length > 0
    ) {
      const primaryImage = propertyDetails?.node?.media?.photoGallery?.hotelView?.edges?.filter(
        (node: any) => node?.isPrimary || node?.isPrimaryArchitectural || node?.isPrimaryServices
      );
      if (
        primaryImage &&
        primaryImage?.length > 0 &&
        primaryImage[0]?.imageUrls?.classicHorizontal !== '' &&
        primaryImage[0]?.imageUrls?.classicHorizontal !== null
      ) {
        const imageUrl = `${IMAGE_URL_DOMAIN}${primaryImage[0]?.imageUrls?.classicHorizontal}?downsize=228:*`;
        images['defaultImageUrl'] = imageUrl;
        courseImages.push({ ...images });
      } else if (
        propertyDetails?.node?.media?.photoGallery?.hotelView?.edges[0]?.node?.imageUrls?.classicHorizontal !== '' &&
        propertyDetails?.node?.media?.photoGallery?.hotelView?.edges[0]?.node?.imageUrls?.classicHorizontal !== null
      ) {
        const imageUrl = `${IMAGE_URL_DOMAIN}${propertyDetails?.node?.media?.photoGallery?.hotelView?.edges[0]?.node?.imageUrls?.classicHorizontal}?downsize=228:*`;
        images['defaultImageUrl'] = imageUrl;
        courseImages.push({ ...images });
      }
    }

    if (courseImages?.length === 0) {
      const imageUrl = `${props?.dynamicMedia?.assetPath}?downsize=228:*`;
      images['defaultImageUrl'] = imageUrl;
      courseImages.push(images);
    }
    return courseImages?.length > 5 ? courseImages?.slice(0, 5) : courseImages;
  };

  const getPropertyAddress = (propertyDetails: any) => {
    if (
      propertyDetails?.node?.contactInformation &&
      propertyDetails?.node?.contactInformation?.address &&
      (propertyDetails?.node?.contactInformation?.address?.city ||
        propertyDetails?.node?.contactInformation?.address?.stateProvince?.description)
    ) {
      let address = '';
      if (propertyDetails?.node?.contactInformation?.address?.city)
        address += propertyDetails?.node?.contactInformation?.address?.city;
      if (
        propertyDetails?.node?.contactInformation?.address?.city &&
        propertyDetails?.node?.contactInformation?.address?.stateProvince?.description
      )
        address += ', ';
      if (propertyDetails?.node?.contactInformation?.address?.stateProvince?.description)
        address += propertyDetails?.node?.contactInformation?.address?.stateProvince?.description;

      return address;
    } else return '';
  };

  const getViewHotelDetailsUrl = (propertyDetails: any) => {
    const hwsWebUrl = propertyDetails?.node?.basicInformation?.websiteUrl;
    if (
      propertyDetails?.node?.basicInformation?.websiteUrl &&
      propertyDetails?.node?.basicInformation?.websiteUrl !== null &&
      propertyDetails?.node?.basicInformation?.websiteUrl !== ''
    ) {
      if (hwsWebUrl.includes('overview')) {
        const updatedHwsWebUrl = hwsWebUrl.replace('overview', 'golf');
        return updatedHwsWebUrl;
      } else return hwsWebUrl;
    } else {
      if (propertyDetails?.node?.basicInformation?.brand?.id === 'RZ') {
        return `https://www.ritzcarlton.com/en/hotels/${propertyDetails?.node?.id?.toLowerCase()}/golf/`;
      } else return `https://www.marriott.com/en-us/hotels/${propertyDetails?.node?.id?.toLowerCase()}/golf/`;
    }
  };

  const getFooterLinks = (propertyDetails: any) => {
    if (propertyDetails?.node?.id) {
      const footerlinks = {
        href: getViewHotelDetailsUrl(propertyDetails),
        target: `${props?.openInNewTab ? '_blank' : '_self'}`,
        rel: 'noreferrer',
        className: 'm-button-s m-button-primary custom_click_track reserve-cta-button',
        text: props?.viewHotelDetailsLabel,
        isLink: false,
        ratesClickTrackValue: `${GOLF_COURSE_LOCATION_CARD}|${propertyDetails?.node?.basicInformation?.name} - ${props?.viewHotelDetailsLabel} CTA|${EXTERNAL_LINK}`,
      };
      return footerlinks;
    }
    return;
  };

  const getTabList = () => {
    const tabList = props?.tabList?.map(tabItem => ({
      tabTitle: tabItem.tabTitle,
      tabSubtitle: '',
      tabValue: tabItem.tabValue,
    }));
    return tabList?.length ? tabList : [];
  };

  return (golfCourseLocationsList?.edges && golfCourseLocationsList?.edges?.length > 0) ||
    golfCourseLocationsLoading ? (
    <StyledGolfLocationContainer id="golfLocationsContainer">
      <TabComponent
        tabList={getTabList()}
        children={
          <div className={clsx('pb-5 px-0')}>
            {hideHeader && <HideGlobalHeader />}
            {golfCourseLocationsLoading ? (
              <div
                className="spinner-wrapper d-flex justify-content-center align-items-center vh-100"
                data-testid="loader"
              >
                <GlobalStyle />
                <div className="m-spinner-m"></div>
              </div>
            ) : (
              <>
                <SearchAllFilters
                  {...props}
                  isMobileViewPort={isMobileViewPort}
                  currentPage={currentPageFromContext}
                  selectedTab={selectedTab}
                />
                <StyledVerticalGolfCards className="mt-4">
                  {golfCourseLocationsList?.edges?.map((property: any, index: number) => {
                    return (
                      <PropertyCard
                        titleDetails={getPropertyName(property)}
                        images={getPropertyImages(property)}
                        isVerticalCard={true}
                        brandDetails={{
                          brandId: property?.node?.basicInformation?.brand?.id,
                          label: property?.node?.basicInformation?.brand?.name,
                        }}
                        address={getPropertyAddress(property)}
                        description={property?.node?.basicInformation?.descriptions[0]?.text}
                        showDescriptionAlways="true"
                        footerLinkDetails={getFooterLinks(property)}
                        key={`golf-location-${index}`}
                      />
                    );
                  })}
                </StyledVerticalGolfCards>
                <div className="pagination-container mt-5" data-testid="pagination-panel">
                  <PaginationPanel
                    currentPage={currentPage}
                    totalCount={golfCourseLocationsList?.total}
                    pageSize={GolfLocationsPageSize}
                    onPageChange={(page: number): void => onPageItemClick(page)}
                    clickTrackLocation={GOLF_LOCATIONS_PAGINATION}
                  />
                </div>
              </>
            )}
          </div>
        }
        customClass="t-font-s"
        selectedTab={selectedTab}
        setSelectedTab={(selectedTab: string) => {
          onTabChange(selectedTab);
        }}
        classTabsEnabled="regional-sub-nav"
      />
    </StyledGolfLocationContainer>
  ) : null;
};

export const GolfCourseLocationsContainerFeatureConfig = {
  emptyLabel: 'golflocationscard',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/golflocationscard`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GolfCourseLocationsEditable = (props: any) => (
  <EditableComponent config={GolfCourseLocationsContainerFeatureConfig} {...props}>
    <GolfCourseLocationsContainer {...props} />
  </EditableComponent>
);
